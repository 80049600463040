<template>
<div class="footer">
	<div class="info">
		<div>
			<img src="../assets/public/info1.png" alt="">
			<p>不良信息 <br/>举报中心</p>
		</div>
		<div>
			<img src="../assets/public/info2.png" alt="">
			<p>支付宝<br/>约商家</p>
		</div>
		<div>
			<img src="../assets/public/info3.png" alt="">
			<p>上海工商 <br/>企业信息</p>
		</div>
		<div>
			<img src="../assets/public/info4.png" alt="">
			<p>中国科学探险协会 <br/>会员单位</p>
		</div>
		<div>
			<img src="../assets/public/info5.png" alt="">
			<p>上海消协<br/> 网络单位</p>
		</div>
		<div>
			<img src="../assets/public/info6.png" alt="">
			<p>上海旅游行业协会 <br/>在线分会</p>
		</div>
	</div>
	<div class="nav">
		<ul>
			
			<li v-for="(item,i) in usernav" :key="i">
				<router-link :to="item.to"> {{item.name}} </router-link>
			</li>
			<li>
				<a @click="openViewer(1)" class="hand">营业执照 </a>
			</li>
			<li>
				<a @click="openViewer(0)" class="hand">旅行社经营许可证 </a>
			</li>
		</ul>
	</div>
	
	<div class="line"></div>
	<p>
		<span>上海趣游文化传播有限公司版权所有</span>
		<span>Copyright &copy; 2002-2020 Powered By Version 4.5</span>
	</p>
	<p>
		趣游网所有活动均由上海趣游国际旅行社 （许可证号：L-SH-CJ00093）及其他具有相关资质的旅游企业提供服务
	</p>
	<p>
		<a href="https://beian.miit.gov.cn/#/Integrated/recordQuery" target="_bank">沪ICP备05000989号-1</a>
		<img src="../assets/public/p.png" alt="">
		<span>沪公网安备 31010902103579号</span> 
		<el-image-viewer v-if="showViewer" :on-close="showViewerClose" :url-list="[img[index]]"></el-image-viewer>
	</p>
</div>
</template>

<script>
import ElImageViewer from 'element-ui/packages/image/src/image-viewer'
export default {
components:{ ElImageViewer },
  data () {
    return {
      usernav: [
        {
          name: '关于趣游',
          to: '/quyou/about',
        }, {
          name: '公关策划',
          to: '/quyou/PR'
        }, {
          name: '广告服务',
          to: '/quyou/advertisement'
        }, {
          name: '联系我们',
          to: '/quyou/contact'
        }, {
          name: '意见反馈',
          to: '/quyou/opinion'
        }, {
          name: '交换链接',
          to: '/quyou/exchangelinks'
        }, {
          name: '隐私保护',
          to: '/quyou/privacy'
        }, {
          name: '加入趣游',
          to: '/quyou/joinus'
        }
      ],
	  img:["http://yzhs-1253640091.cos.ap-shanghai.myqcloud.com/365d4911199acbe6e0c814d55fad79da.png"
		  ,"http://yzhs-1253640091.cos.ap-shanghai.myqcloud.com/3f77f6a92c9c39e0153891ab43c014fd.png"],
	  showViewer:false,
	  index:0
    }
  },
  created () {

  },
  methods: {
	  openViewer(n){
		  this.index=n
		  this.showViewer = true
	  },
	  showViewerClose(){
		  this.showViewer = false
	  },
    touserurl (url) {
      this.$router.push({ path: url })
    }
  }
}
</script>

<style lang="scss" scoped="scoped">
.footer{
	width: 1200px;
	margin: 20px auto;
	.info{
		display: flex;
		justify-content: center;
		div{
			display: flex;
			align-items: center;
			padding: 4px 8px;
			border: 1px solid #e6e6e6;
			border-radius: 4px;
			margin-left: 20px;
			img{
				width: 27px;
				height: 26px;
			}
			p{

				height: 34px;
				font-size: 12px;
				margin-left: 10px;
				font-weight: 400;

				color: #6d380c;
				line-height: 17px;
			}
		}
	}

	.nav{
		display: flex;
		justify-content: center;
		margin-top: 10px;
		ul{
			display: flex;
			li{
				color:#8a8a8a ;
				a{
					margin:0 15px ;
					font-size: 14px;
					font-weight: 400;
					color: #666666;
					line-height: 30px;

				}
			}
		}
	}
	.link{
		display: flex;
		justify-content: center;
		margin-top: 10px;
		ul{
			display: flex;
			align-items: center;
			li{
				color:#6D380C ;
				height: 30px;
				line-height: 30px;
				a{
					margin:0 5px ;
					font-size: 14px;
					font-weight: 400;
					color: #999999;
					line-height: 30px;

				}
			}
		}
	}
	.line{
		width: 1200px;
		margin: 5px auto;
		height: 1px;
		border-bottom: 1px dashed rgba(151,151,151,0.55);
	}
	>p{
		display: flex;
		justify-content: center;
		align-items: center;
		margin-top:10px ;
		height: 20px;
		font-size: 14px;
		color: #999999;
		line-height: 20px;
		span{
			color: #999999;
			margin: 0 10px;
		}
	}

}

</style>
